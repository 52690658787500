<template>
  <a-modal
    :visible="visible"
    :title="initValue.id ? '编辑' : '新建'"
    :mask-closable="false"
    :width="600"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    :getContainer="container"
  >
    <a-form
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
      ref="formRef"
      :model="question"
    >
      <a-form-item
        label="名称"
        :name="['name']"
        :rules="[required, whitespace]"
      >
        <a-input
          v-model:value="question.name"
          placeholder="请输入名称"
          :maxlength="64"
        />
      </a-form-item>
      <a-form-item label="数据来源" :name="['sourcesId']" :rules="[required]">
        <a-select
          v-model:value="question.sourcesId"
          placeholder="请选择数据来源"
          :options="memberGroupList"
          show-search
          @change="handleChangeByMemberGroup"
          :filter-option="
                (inputValue, option) =>
                  handleFilterOption(inputValue, option)
              "
        >
        </a-select>
      </a-form-item>
      <a-form-item
        label="评分模型"
        :name="['scoreId']"
        :rules="[
          {
            required: question.sourcesId === '0' || question.sourcesId === '1',
            message: '不能为空',
            trigger: 'change',
          },
        ]"
      >
        <a-select
          v-model:value="question.scoreId"
          placeholder="请选择评分模型"
          :options="scoreModelList"
          @change="handleChangeByScore"
        >
        </a-select>
        <!--     :auto-adjust-overflow="false"
         placement="right"
:get-popup-container="getPopupContainer"
  -->
        <a-tooltip placement="top" title="建议使用推荐评分模型">
          <QuestionCircleFilled class="tip-icon" />
        </a-tooltip>
      </a-form-item>
      <a-form-item label="分阶设定" :name="['middleMax']" :rules="[required]">
        <a-row type="flex">
          <a-col :span="9">
            <span>{{ question.lowMin }}</span> -
            <a-input-number
              v-model:value="question.lowMax"
              @change="value => handlelowMax(value)"
            />
          </a-col>
          <a-col :span="9">
            <span>{{ question.middleMin }}</span> -
            <a-input-number v-model:value="question.middleMax" />
          </a-col>
          <span class="initial-m"> {{ question.high }} 以上</span>
        </a-row>

        <a-row type="flex" class="s-m">
          <a-col :span="8">
            <span>低</span>
          </a-col>
          <a-col :span="8">
            <span class="middle">中</span>
          </a-col>
          <a-col :span="8">
            <span class="high">高</span>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, toRaw, toRefs, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  Form,
  Select,
  Tooltip,
  InputNumber,
  Col,
  Row,
  message,
} from 'ant-design-vue';
import { QuestionCircleFilled } from '@ant-design/icons-vue';
// import clueApi from '@/api/clue';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ATooltip: Tooltip,
    ASelect: Select,
    QuestionCircleFilled,
    AInputNumber: InputNumber,
    ACol: Col,
    ARow: Row,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
    memberGroupList: {
      type: Array,
      default: () => [],
    },
    scoreModelList: {
      type: Array,
      default: () => [],
    },
    container: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const route = useRoute();
    const required = { required: true, message: '不能为空' };
    const whitespace = {
      whitespace: true,
      message: '不能为空',
      trigger: 'change',
    };
    const formRef = ref();
    const question = reactive({
      id: '',
      name: '',
      sourcesId: null,
      scoreId: null,
      sourcesName: '',
      scoreName: '',
      tiered: '',
      lowMin: 0,
      lowMax: null,
      middleMin: 0,
      middleMax: null,
      high: null,
    });
    const state = reactive({ formList: [], agreementList: [] });
    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(question, initValue);
        } else {
          Object.assign(question, {
            id: '',
            name: '',
            sourcesId: null,
            scoreId: null,
            sourcesName: '',
            scoreName: '',

            lowMin: 0,
            lowMax: '',
            middleMin: 0,
            middleMax: '',
            high: 0,
          });
        }
      }
    );

    watch(
      () => question.middleMax,
      () => {
        if (
          question.middleMax !== '' &&
          typeof question.middleMax !== 'object'
        ) {
          question.high = question.middleMax + 1;
        } else {
          question.high = 0;
        }
      }
    );
    const getPopupContainer = trigger => {
      return trigger.parentElement;
    };
    return {
      ...toRefs(state),
      formRef,
      required,
      route,
      question,
      whitespace,
      // validateInfos,
      getPopupContainer,
      handleCancel: () => context.emit('update:visible', false),

      handleFilterOption(inputValue, option,  ) {
        return option.label.indexOf(inputValue) >= 0;
      },

      handleOk: () => {
        let data = { ...question };
        formRef.value.validate().then(() => {
          if (question.lowMax && question.middleMax > 999) {
            return message.error('分阶设定错误，值不能超过999');
          }

          if (
            question.lowMin < question.lowMax &&
            question.middleMin < question.middleMax
          ) {
            context.emit('fnOk', toRaw(data));
          } else {
            return message.error('分阶设定错误，右侧数字必须大于左侧数字');
          }
        });
      },
      handleChangeByMemberGroup: () => {
        question.sourcesName = props.memberGroupList.find(
          item => item.value === question.sourcesId
        ).label;
        formRef.value.clearValidate();
      },
      handleChangeByScore: () =>
        (question.scoreName = props.scoreModelList.find(
          item => item.value === question.scoreId
        ).label),

      handlelowMax: value => {
        if (value !== '' && typeof value !== 'object') {
          question.middleMin = value + 1;
        } else {
          question.middleMin = 0;
        }
      },
    };
  },
};
</script>

<style scoped lang="less">
.tip-icon {
  top: 8px;
  position: absolute;
  margin-left: 8px;
}
.initial-m {
  margin: 5px;
}
.s-m {
  text-align: center;
}
.middle {
  margin-left: 24px;
}
.high {
  margin-left: -17px;
}
</style>
